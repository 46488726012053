import React, { useState, useRef } from "react";
import styled from "styled-components";
import emailjs from "@emailjs/browser";
import { fonts, contactUsStyles } from "../../styles.jsx";

const REACT_APP_SERVICE_ID = "service_gabzgb9";
const REACT_APP_TEMPLATE_ID = "template_gjkdzck";
const REACT_APP_PUBLIC_KEY = "uxvhR16ewbq6zgg0x";

const { mont } = fonts;
const { secondary, white, superPowersRed, salmon } = contactUsStyles;

const ColorBackground = styled.div`
  background-color: ${secondary};
  border-radius: 20px;
  width: 40%;
  margin: 0 auto;
  min-width: 280px;
`;

const ContactWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: ${mont};
`;

const TitleWrapper = styled.label`
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  color: ${white};
  height: 64px;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 10px;
  border-radius: 4px;
`;

const FormWrapper = styled.form`
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 280px;
`;

const Label = styled.h3`
  width: 100%;
  margin-top: 5px;
  margin-bottom: -5px;
  text-align: center;
  font: ${mont};
  color: ${white};
`;

const Name = styled.input.attrs((props) => ({
  name: "from_name",
  type: "text",
  required: (props.required = true),
}))`
  width: 90%;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 16px;
  resize: none;
`;

const Email = styled.input.attrs((props) => ({
  name: "user_email",
  type: "email",
  required: (props.required = true),
  pattern: "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}",
}))`
  width: 90%;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 16px;
  resize: none;
`;

const ValidationMessage = styled.span`
  color: ${superPowersRed};
  font-size: 12px;
`;

const Message = styled.textarea.attrs((props) => ({
  name: "message",
  type: "text",
  required: (props.required = true),
}))`
  width: 90%;
  height: 180px;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 16px;
  resize: none;
`;

const SubmitButton = styled.button`
  height: 40px;
  width: 80px;
  margin-top: 16px;
  font-size: 16px;
  color: ${white};
  background-color: ${salmon};
  border: ${salmon};
  border-radius: 20px;
  cursor: pointer;
  &:disabled {
    opacity: 0.7;
  }
  &:enabled {
    opacity: 1;
  }
`;

const LoadingSpinner = styled.div`
  margin-top: 24px;
  margin-bottom: 32px;
  border-width: 8px;
  border-style: solid;
  border-color: ${secondary} ${secondary} ${secondary} ${secondary};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  -webkit-animation: spin 2s infinite;
  animation: spin 2s infinite;

  &:before,
  &:after {
    content: "";
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: ${white};
    position: absolute;
    left: 35px;
  }

  &:before {
    top: 1px;
  }

  &:after {
    bottom: 1px;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

const ThankYou = styled.div`
  margin-top: 100px;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${secondary};
  font-size: 24px;
  font-family: ${mont};
  text-align: center;
  width: 100%;
`;

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const form = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!emailValid) {
      return;
    }

    emailjs
      .sendForm(REACT_APP_SERVICE_ID, REACT_APP_TEMPLATE_ID, form.current, {
        publicKey: REACT_APP_PUBLIC_KEY,
      })
      .then(
        () => {
          setLoading(false);
          setSent(true);
        },
        (error) => {
          setLoading(false);
        },
      );
  };

  const handleEmailChange = (e) => {
    setEmailValue(e.target.value);
    setEmailValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value));
  };

  if (sent) return <ThankYou>Your message has been sent, thank you!</ThankYou>;

  return (
    <ColorBackground>
      <ContactWrapper>
        <TitleWrapper>Contact Us Today!</TitleWrapper>
        <FormWrapper ref={form} onSubmit={handleSubmit}>
          <Label>Name</Label>
          <Name />
          <Label>Your Email Address</Label>
          <Email onChange={handleEmailChange}></Email>
          {emailValue && !emailValid && (
            <ValidationMessage>
              Please enter a valid email address.
            </ValidationMessage>
          )}
          <Label>Your Message</Label>
          <Message />
          {loading ? (
            <LoadingSpinner></LoadingSpinner>
          ) : (
            <SubmitButton type="submit" disabled={!emailValid}>
              Submit
            </SubmitButton>
          )}
          <input
            type="text"
            name="location"
            value={window.location.href}
            style={{ display: "none" }}
          ></input>
        </FormWrapper>
      </ContactWrapper>
    </ColorBackground>
  );
};

export default ContactUs;
