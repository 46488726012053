import React from "react";
import styled, { keyframes } from "styled-components";
import "./Cloud.css";
import { homeStyles } from "../../styles";

const moveSun = keyframes`
0% { 
  left: -50%;
  top: 50%;
}
10% { 
  left: -30%; 
  top: 35%;
}
20% { 
  left: -10%; 
  top: 20%;
}
30% { 
  left: 10%; 
  top: 10%;
}
40% { 
  left: 30%; 
  top: 4%;
}
45% {
  left: 40%;
  top: 1%;
}
47.5% {
  left: 45%;
  top: 0.3%;
}
50% { 
  left: 50%; 
  top: 0%;
}
52.5% {
  left: 55%;
  top: 0.3%;
}
55% {
  left: 60%;
  top: 1%;
}
60% { 
  left: 70%; 
  top: 4%;
}
70% { 
  left: 90%; 
  top: 10%;
}
80% { 
  left: 110%; 
  top: 20%;
}
90% { 
  left: 130%; 
  top: 35%;
}
100% { 
  left: 150%; 
  top: 50%;
}
`;

// Create a styled component for the sun
export const SunWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 50px;
  background-color: ${homeStyles.sun};
  border-radius: 50%;
  animation: ${moveSun} 30s linear infinite;
  z-index: 0;
`;

const moveCloudLoop = keyframes`
  0% { left: -150%; }
  100% { left: 150%; }
`;

const CloudAWrapper = styled.div`
  position: absolute;
  top: 40px;
  z-index: 1;
  overflow: hidden;
  background-size: contain;
  animation: ${moveCloudLoop} 26s linear infinite;
`;

const CloudBWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 300px;
  overflow: hidden;
  animation: ${moveCloudLoop} 32s linear infinite;
  background-size: contain;
`;

const CloudsWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  z-index: 3;
  .cloud {
    position: absolute;
    &:nth-child(1) {
      left: -25%;
    }
    &:nth-child(2) {
      left: 0%;
    }
    &:nth-child(3) {
      left: 20%;
    }
    &:nth-child(4) {
      left: 45%;
    }
    &:nth-child(5) {
      left: 65%;
    }
    &:nth-child(6) {
      left: 90%;
    }
  }
`;

export const CloudA = () => (
  <CloudAWrapper>
    <div className="cloud a" style={{ background: "#5AB3F0" }} />
  </CloudAWrapper>
);

export const CloudB = () => (
  <CloudBWrapper>
    <div className="cloud b" style={{ background: "#5AB3F0" }} />
  </CloudBWrapper>
);

export const Clouds = () => (
  <CloudsWrapper>
    <div className="cloud a" />
    <div className="cloud b" />
    <div className="cloud b" />
    <div className="cloud a" />
    <div className="cloud a" />
    <div className="cloud b" />
  </CloudsWrapper>
);
