import styled from "styled-components";
import React, { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  homeStyles,
  fonts,
  ourStoryStyles,
  resourcesStyles,
  getInvolvedStyles,
  skyColorChange,
} from "../../styles.jsx";
import { Card } from "./ResusableComponents.jsx";
import headerImage from "../../images/whyChildhood.svg";
import Hamburger from "./Hamburger.jsx";

const { mont, fred } = fonts;

const getPageColor = ({ page }) => {
  switch (page) {
    case "home":
      return homeStyles.primary;
    case "our_story":
    case "donate":
      return ourStoryStyles.primary;
    case "resources":
      return resourcesStyles.green;
    case "get_involved":
      return getInvolvedStyles.yellow;
    default:
      return homeStyles.primary;
  }
};

// Apply the animation to the HeaderWrapper
const OGHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${getPageColor}; // initial color
  height: 100px;
`;
const AnimatedHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100px;
  animation: ${skyColorChange} 30s linear infinite;
`;
const HeaderWrapper = ({ page, children }) =>
  page === "home" ? (
    <AnimatedHeader page={page}>{children}</AnimatedHeader>
  ) : (
    <OGHeader page={page}>{children}</OGHeader>
  );

const TopLeft = styled.div`
  padding: 16px;
  display: flex;
  justify-content: flex-start;

  @media (max-width: 900px) {
    padding: 16px;
  }
`;

const TopRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 64px 0 32px;
  font-family: ${mont};

  @media (max-width: 800px) {
    display: none;
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 0;
  @media (max-width: 400px) {
    flex-direction: column;
    padding: 0;
  }
`;

const StyledLink = styled(Link)`
  color: ${homeStyles.white};
  text-decoration: ${({ underline }) => underline};
  text-decoration-thickness: 2px;
  margin: 1rem;
  position: relative;
  white-space: nowrap;
`;

const WhyHeaderImage = styled.img`
  height: 70px;
  width: 240px;
`;

export const DonateCard = styled(Card)`
  font-size: 1.2em;
  color: ${homeStyles.white};
  font-weight: 900;
  font-family: ${fred};
  padding: 16px;
  background-color: ${getInvolvedStyles.salmon};
  margin: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ size }) => (size === "big" ? "width: 252px;" : "")}
`;

export const DonateButton = ({ size }) => {
  const formRef = useRef();
  const history = useHistory();

  const handleWrapperClick = (e) => {
    e.preventDefault();
    formRef.current.submit();
    history.push("/donate");
  };
  return (
    <form
      action="https://www.paypal.com/donate"
      method="post"
      target="_blank"
      ref={formRef}
    >
      <input type="hidden" name="hosted_button_id" value="YEYMRUZRTYEGS" />
      <DonateCard size={size} onClick={handleWrapperClick}>
        Donate
      </DonateCard>
    </form>
  );
};

const Header = ({ page }) => {
  const [underline, setUnderline] = useState(page);

  const handleMouseEnter = (link) => {
    setUnderline(link);
  };

  const handleMouseLeave = () => {
    setUnderline(page);
  };

  return (
    <HeaderWrapper page={page}>
      <TopLeft>
        <Link to="/">
          <WhyHeaderImage src={headerImage} alt="header_image" />
        </Link>
      </TopLeft>
      <TopRight>
        <Links>
          <StyledLink
            to="/"
            underline={underline === "home" ? "underline" : "none"}
            onMouseEnter={() => handleMouseEnter("home")}
            onMouseLeave={handleMouseLeave}
          >
            Home
          </StyledLink>
          <StyledLink
            to="/our-story"
            underline={underline === "our_story" ? "underline" : "none"}
            onMouseEnter={() => handleMouseEnter("our_story")}
            onMouseLeave={handleMouseLeave}
          >
            Our Story
          </StyledLink>
          <StyledLink
            to="/donate"
            underline={underline === "donate" ? "underline" : "none"}
            onMouseEnter={() => handleMouseEnter("donate")}
            onMouseLeave={handleMouseLeave}
          >
            Support Us
          </StyledLink>
        </Links>
        <DonateButton size={"small"} />
      </TopRight>
      <Hamburger />
    </HeaderWrapper>
  );
};
export default Header;
